import React, { useContext, useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import StoreIcon from '@material-ui/icons/Store';
import SpeedIcon from "@material-ui/icons/Speed";
import GroupIcon from "@material-ui/icons/Group";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ForumIcon from "@material-ui/icons/Forum";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from '@material-ui/icons/Send';
import MessageIcon from '@material-ui/icons/Message';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TimerIcon from '@material-ui/icons/Timer';
import { HiOutlineChat } from "react-icons/hi";
import { LiaUserClockSolid } from "react-icons/lia";
import { IoPeopleOutline } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsBoxArrowInDownRight } from "react-icons/bs";
import { TbClockPause } from "react-icons/tb";

import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { toast } from "react-toastify";

import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";

import { AuthContext } from "../../context/Auth/AuthContext";

import useDashboard from "../../hooks/useDashboard";
import useTickets from "../../hooks/useTickets";
import useUsers from "../../hooks/useUsers";
import useContacts from "../../hooks/useContacts";
import useMessages from "../../hooks/useMessages";
import { ChatsUser } from "./ChartsUser"
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Filters from "./Filters";
import { isEmpty } from "lodash";
import moment from "moment";
import { ChartsDate } from "./ChartsDate";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.padding,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 240,
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  iframeDashboard: {
    width: "100%",
    height: "calc(100vh - 64px)",
    border: "none",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 120,
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
    card0: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15%",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
      card00: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card1: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card3: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card4: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card5: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card6: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card7: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card8: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  card9: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    borderRadius: "15px",
    backgroundColor: "#F9FBFC",
    color: "#000",
  },
  fixedHeightPaper2: {
    padding: theme.spacing(2),
    marginTop: "15px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  
  pdfButton: {
    marginBottom: theme.spacing(2),
  },
  popupButton: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  }
  
}));

const Dashboard = () => {
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [period, setPeriod] = useState(0);
  const [filterType, setFilterType] = useState(1);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState("");

  const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => setOpenPopup(false);
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let now = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`;

  const [showFilter, setShowFilter] = useState(false);
  const [queueTicket, setQueueTicket] = useState(false);

  const { user } = useContext(AuthContext);
  var userQueueIds = [];

  if (user.queues && user.queues.length > 0) {
    userQueueIds = user.queues.map((q) => q.id);
  }

  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
    async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  const exportToPDF = () => {
    const input = document.getElementById("dashboard-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("dashboard.pdf");
    });
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }

  const fetchTicketsData = async () => {
    const token = localStorage.getItem("token");
  
    try {
      let url = 'https://hmlapibellinati.vend.app.br/tickets';
      if (status) {
        url += `?status=${status}`;
      }

       const response = await fetch(url, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(token)}`,
          "Content-Type": "application/json"
        },
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error("Erro ao buscar dados do ticket");
      }
  
      const data = await response.json();
      console.log("Dados recebidos da API:", data);
  
      if (data && Array.isArray(data.tickets)) {
        return data.tickets;
      } else {
        console.error("Formato de dados inválido:", data);
        return [];
      }
  
    } catch (error) {
      console.error("Erro ao buscar dados dos tickets:", error);
      return [];
    }
  };

  const fetchContactData = async () => {
    const token = localStorage.getItem("token");
    
    try {
      const url = 'https://hmlapibellinati.vend.app.br/contacts';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${JSON.parse(token)}`,
          "Content-Type": "application/json"
        },
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error("Erro ao buscar dados de contatos");
      }
  
      const data = await response.json();
      console.log("Dados recebidos da API de Contatos:", data);
  
      if (data && Array.isArray(data.contacts)) {
        return data.contacts;
      } else {
        console.error("Formato de dados inválido para contatos:", data);
        return [];
      }
    } catch (error) {
      console.error("Erro ao buscar dados dos contatos:", error);
      return [];
    }
  };

   {/* const fetchCompanyData = async () => {
    const token = localStorage.getItem("token");
    
    try {
        const url = 'https://hmlapibellinati.vend.app.br/companies';
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${JSON.parse(token)}`,
                "Content-Type": "application/json"
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error("Erro ao buscar dados de empresas");
        }

        const data = await response.json();
        console.log("Dados recebidos da API de Empresas:", data);

        if (data && Array.isArray(data.companies) && data.companies.length > 0) {
            return data.companies[0]; 
        } else {
            console.error("Formato de dados inválido para empresas:", data);
            return null;
        }
    } catch (error) {
        console.error("Erro ao buscar dados das empresas:", error);
        return null;
    }
  }; */}

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    
    try {
        const url = 'https://hmlapibellinati.vend.app.br/users';
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${JSON.parse(token)}`,
                "Content-Type": "application/json"
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error("Erro ao buscar dados de usuários");
        }

        const data = await response.json();
        console.log("Dados recebidos da API de Usuários:", data);

        if (data && Array.isArray(data.users) && data.users.length > 0) {
            return data.users; 
        } else {
            console.error("Formato de dados inválido para usuários:", data);
            return [];
        }
    } catch (error) {
        console.error("Erro ao buscar dados dos usuários:", error);
        return [];
    }
  };

  const fetchMessageHistory = async (ticketId) => {
    const token = localStorage.getItem("token");

    try {
        const url = `https://hmlapibellinati.vend.app.br/messages/${ticketId}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${JSON.parse(token)}`,
                "Content-Type": "application/json"
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error("Erro ao buscar histórico de mensagens");
        }

        const messages = await response.json();
        const messagesArray = messages.messages;

        if (!Array.isArray(messagesArray)) {
            throw new Error("O histórico de mensagens não está no formato esperado.");
        }

        let messageHistory = `Qtde Mensagens: ${messagesArray.length}\n`;
        messageHistory += `-----------Mensagens------------\n`;

        messagesArray.forEach((message) => {
            const origin = message.fromMe ? "Operador" : "Cliente";
            const date = new Date(message.createdAt).toLocaleString("pt-BR");
            const sanitizedMessage = message.body
                .replace(/"/g, '""')
                .replace(/(\r\n|\n|\r)/gm, " | ");
            messageHistory += `${origin}: "${sanitizedMessage}" - ${date}\n`;
        });

        return `"${messageHistory.trim()}"`;
        
    } catch (error) {
        console.error("Erro ao buscar histórico de mensagens:", error);
        return `"Erro ao buscar mensagens: ${error.message}"`;
    }
  };

  const GetUsers = () => {
    let count;
    let userOnline = 0;
    attendants.forEach(user => {
      if (user.online === true) {
        userOnline = userOnline + 1
      }
    })
    count = userOnline === 0 ? 0 : userOnline;
    return count;
  };
  
    const GetContacts = (all) => {
    let props = {};
    if (all) {
      props = {};
    }
    const { count } = useContacts(props);
    return count;
  };

  const downloadCSV = async () => {
    const ticketData = await fetchTicketsData();
    const contactData = await fetchContactData();
    // const companyData = await fetchCompanyData();
    const userData = await fetchUserData();

    if (!ticketData || ticketData.length === 0) {
        alert("Erro ao obter os dados do ticket para exportação.");
        return;
    }

    const contactMap = contactData.reduce((map, contact) => {
        map[contact.id] = contact;
        return map;
    }, {});

    const csvContent = [
        ["Id", "Nome do Cliente", "Telefone", "CNPJ/CPF", "Nome do Operador", "CPF operador", "Tags", "Status", "Contrato", "Criação do Atendimento", "Último Atendimento", "Mensagens"]
    ];

    const adjustDate = (dateString) => {
      const date = new Date(dateString);
      date.setHours(date.getHours());
      return date.toLocaleString("pt-BR");
    };

    for (const ticket of ticketData) {
        const contact = contactMap[ticket.contactId];
        const tagString = ticket.tags ? ticket.tags.map(tag => tag.name).join(", ") : "Sem tags";
        const user = userData.find(u => u.id === ticket.userId);
        
        const messageHistory = await fetchMessageHistory(ticket.id);

        csvContent.push([
            ticket.id || "",
            // companyData.name || "",
            // companyData.cdGrupoem || "",
            contact ? contact.name : "",
            contact ? contact.number : "",
            contact ? contact.cnpj_cpf : "CPF não encontrado",
            user ? user.name : "",
            user ? user.cpfUser : "",
            tagString,
            ticket.status || "",
            contact ? contact.contract : "Contrato não encontrado",
            adjustDate(ticket.createdAt) || "",
            adjustDate(ticket.updatedAt) || "",
            messageHistory || "Sem mensagens"
        ]);
    }

    const csvString = csvContent.map(e => e.map(field => {
        if (typeof field === "string") {
            return `"${field.replace(/"/g, '""')}"`; 
        }
        return `"${String(field)}"`; 
    }).join(",")).join("\n");

    const csvData = `data:text/csv;charset=utf-8,\uFEFF${csvString}`;
    const encodedUri = encodeURI(csvData);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Relatório.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
     <Container maxWidth="lg" className={classes.container}>
      {/* Botão para exportar PDF */}
      <Button
        variant="contained"
        color="primary"
        onClick={exportToPDF}
        className={classes.pdfButton}
      >
        Exportar PDF
      </Button>

      {/* Botão para abrir Popup */}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpenPopup}
        className={classes.popupButton}
      >
        Relatório CSV
      </Button>

      {/* Popup com filtro de data e status */}
      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>Filtro de Data e Status</DialogTitle>
        <DialogContent>
        <form>
      {/* Filtros de data lado a lado */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="De"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Até"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      {/* Filtro Select de Status */}
      <TextField
        label="Status"
        select
        value={status}
        onChange={handleStatusChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="open">Abertos</MenuItem>
        <MenuItem value="closed">Fechados</MenuItem>
      </TextField>
    </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePopup} color="primary">
          Fechar
        </Button>
        <Button onClick={downloadCSV} color="primary" variant="contained">
          Gerar relatório
        </Button>
      </DialogActions>
      </Dialog>

      {/* Conteúdo do Dashboard */}
      <div id="dashboard-content">
        <Grid container spacing={3} justifyContent="flex-end"> 

        {user.super && (
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card0} elevation={4} style={{ overflow: "hidden"}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant="h6" component="h3" paragraph>Conexões Ativas</Typography>
                  <Grid item>
                    <Typography variant="h4" component="h1">
                      {counters.totalWhatsappSessions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <MobileFriendlyIcon style={{fontSize: 100, color: "#fff"}} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          )}

          {user.super && (
            <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card00} elevation={4} style={{ overflow: "hidden"}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant="h6" component="h3" paragraph>Empresas</Typography>
                  <Grid item>
                    <Typography variant="h4" component="h1">
                      {counters.totalWhatsappSessions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <StoreIcon style={{fontSize: 100, color: "#fff"}} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card1} elevation={4} style={{ overflow: "hidden"}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant="h6" component="h3" paragraph style={{fontWeight: "600"}}>Em conversa</Typography>
                  <Grid item>
                    <Typography variant="h4" component="h1" style={{fontWeight: "bold"}}>
                      {counters.totalWhatsappSessions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <HiOutlineChat style={{fontSize: 70, color: "#000"}} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card2} elevation={4} style={{ overflow: "hidden"}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph style={{ fontWeight: "600"}}> STAND BY </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4" style={{ fontWeight: "bold"}}>
                      {counters.supportPending}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <LiaUserClockSolid style={{ fontSize: 70, color: "#000"}} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card6} elevation={4}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph style={{ fontWeight: "600" }}>OPERADORES ATIVOS</Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4" style={{ fontWeight: "bold" }}>
              
                      <span style={{ color: "#000"}}>
                        {attendants.length}/
                      </span>
                      {GetUsers()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <IoPeopleOutline style={{ fontSize: 70, color: "#000"}} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card3} elevation={4} style={{ overflow: "hidden"}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph style={{ fontWeight: "600"}}> FINALIZADOS </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4" style={{ fontWeight: "bold"}}>
                      {counters.supportFinished}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <IoMdCheckmarkCircleOutline style={{ fontSize: 70, color: "#000"}} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card4} style={{ overflow: "hidden" }} elevation={6}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph style={{ fontWeight: "600" }}>
                    NOVOS CONTATOS
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4" style={{ fontWeight: "bold" }}>
                      {GetContacts(true)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <BsBoxArrowInDownRight style={{ fontSize: 70, color: "#000" }} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card9} elevation={4} style={{ overflow: "hidden" }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant="h6" component="h3" paragraph>T.M. de Espera</Typography>
                  <Grid item>
                    <Typography variant="h4" component="h1">
                      {formatTime(counters.avgWaitTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TbClockPause style={{ fontSize: 70, color: "#000" }} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <div style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            {attendants.length ? (
              <TableAttendantsStatus attendants={attendants}
              loading={loading}
              />
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper2} style={{ borderRadius: "15px" }}>
              <ChatsUser />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper2} style={{ borderRadius: "15px" }}>
              <ChartsDate />
            </Paper>
          </Grid>
        </div>
      </div>
    </Container>
    </div>
  );
};

export default Dashboard;